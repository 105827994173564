import React from "react"

// import { Link } from "gatsby"

// import SwiperCore, { Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.scss"

// import "swiper/components/pagination/pagination.scss"

import ChooseUs from "../why-us/why-us"

// import videoPlaceholder from "../../images/play-button-round.png"

// SwiperCore.use([Pagination])

const AboutSection = () => {
  // const swiperRef = useRef(null)

  // const goNext = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slideNext()
  //   }
  // }
  // const goPrev = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slidePrev()
  //   }
  // }
  return (
    <>
      <div className="about-section-wrapper" id="about-section">
        <div className="about-swiper-bg lazy">
          <Swiper
            // spaceBetween={20}
            slidesPerView={1}
            // pagination={{ clickable: true }}
            // ref={swiperRef}
          >
            <SwiperSlide>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-1">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-6">
                    <p className="title mb-0">about</p>
                    <h2 className="brand">Pacific Coast</h2>
                    <h2 className="brand-services text-white">
                      Land Solutions
                    </h2>
                    <p className="phrase">Why Work with Our Team?</p>
                    <p className="lead mb-0 mt-5">
                      Pacific Coast Land Solutions was formed with our clients
                      in mind. We have taken years of knowledge and expertise
                      within the Real Estate & Right of Way profession to form a
                      comprehensive organization that you can count on for all
                      your needs. Pacific Coast can assist with the initial
                      planning of projects all the way through cleanup and
                      restoration. Our highly skilled and dedicated staff of
                      professionals are accomplished in all aspects of project
                      development and execution. We are committed to the details
                      of each project, which ensures success and provides our
                      clients with the most positive outcome.
                    </p>
                    <p className="lead mt-5 mb-0">
                      We offer comprehensive support, efficiency, and
                      consistency. Make full use of our years of knowledge and
                      expertise in the field.
                    </p>

                    {/* <Link to="/" type="button" className="request-btn mt-5">
                    READ MORE
                  </Link> */}
                  </div>
                  <div className="col-lg-5">
                    {/* <iframe
                      className="mt-5"
                      id="ytplayer"
                      title="Gray Hawk"
                      type="text/html"
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/vOvwJE8Xuwk?rel=0&enablejsapi=1&modestbranding=1"
                      frameborder="0"
                      allowfullscreen
                    /> */}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <ChooseUs />
      </div>
    </>
  )
}

export default AboutSection
