import React from "react"
import { Link } from "gatsby"

import peopleImg from "../../images/why-us/people.png"
import gearImg from "../../images/why-us/gear.png"
import gadgetImg from "../../images/why-us/gadget.png"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FaCaretDown } from "react-icons/fa";

import WhatToExpect from "./what-to-expect"

const ChooseUs = () => {
  return (
    <>
      <div className="why-us-wrapper">
        <div className="container-fluid">
          <WhatToExpect />
          <div className="col-lg-10 mx-auto mt-5 px-0">
            <h3 className="main-title text-center">
              Why Partner with Pacific Coast?
            </h3>
            <p className="main-lead text-center mt-3">
              Need assistance in laying the groundwork for your projects? How
              about post-completion? Pacific Coast has a team of dedicated
              professionals who will make sure that your plans are executed
              smartly and efficiently. We only have your success in mind.
            </p>
          </div>
          <div className="col-lg-10 mx-auto mt-5 px-0 why-work-box">
            <div className="row" style={{ marginTop: "5rem" }}>
              <div className="col-lg-4 text-center work-box-1">
                <img src={peopleImg} alt="Experienced People" />
                <Link to="/partnerships" className="expect-cta">
                  <h5 className="mt-5 inner-title">Partnerships</h5>
                </Link>
                <p className="inner-lead">
                  Our network includes database and land management companies,
                  top national survey firms, top national appraisal, and
                  evaluation firms, industrial manufacturing firms, Registered
                  Arborists, Right of Way clearing firms, pipeline reclamation
                  firms, and pipeline construction firms. Work with us and not
                  only will you benefit from our expertise, but from that of our
                  partners’ as well.
                </p>
              </div>
              {/* <div className="col-lg-3 text-center">
                <img src={gearImg} alt="Experienced People" />
                <h5 className="mt-5 inner-title">Pricing</h5>
                <p className="inner-lead">
                  We are a better value than our competitors. We can offer
                  one-off rates to our clients in order to make individuals fit
                  necessary parameters that our clients need to operate in.
                </p>
              </div> */}
              <div className="col-lg-4 text-center work-box-2">
                <img src={gearImg} alt="Experienced People" />
                {/* <a href="/" className="expect-cta"> */}
                <h5 className="mt-5 inner-title">Agility</h5>
                {/* </a> */}
                <p className="inner-lead">
                  We understand the value of quick decision making and
                  assertiveness in response to current market conditions. Being
                  agile allows us to react or redirect our efforts to ensure
                  that our clients best interests are at the forefront of all
                  that we do.
                </p>
              </div>
              <div className="col-lg-4 text-center work-box-3">
                <img src={gadgetImg} alt="Experienced People" />
                <h5 className="mt-5">
                  {/* <button
                    className="text-uppercase inner-title"
                    style={{ color: "#248dcb" }}
                    onClick={() => setLgShow(true)}
                  > */}
                  <Link
                    to="/artificial-intelligence"
                    className="text-uppercase inner-title"
                  >
                    Artificial Intelligence
                    <br />
                    Data Extraction
                  </Link>
                  {/* </button> */}
                  {/* <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    centered
                    className="modal-wrapper"
                  >
                    <Modal.Header
                      closeButton
                      style={{ borderBottom: "none" }}
                    />
                    <Modal.Body>
                      <div className="services-description-box mt-3">
                        <iframe
                          width="100%"
                          height="500px"
                          src="https://www.youtube.com/embed/4tP0IYREUbI"
                          frameBorder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Pacific Coast ROW Analyzer"
                        ></iframe>
                      </div>
                    </Modal.Body>
                  </Modal> */}
                </h5>
                <p className="inner-lead">
                  Minimize the stress and resource drain of sorting through huge
                  amounts of information. Our AI data extraction service is the
                  first of its kind and will make data analyses efficient and
                  productive.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-10 mx-auto mt-5 px-0">
            <h3 className="main-title">Why We Created Pacific Coast?</h3>
            <p className="main-lead">
              Pacific Coast Land Solutions was born out of the desire to provide
              value to companies and government sectors that are looking for
              assistance in the Right of Way, real estate and land title fields.
              We provide our years of knowledge and experience to make projects
              go smoothly and quickly.
            </p>
            <p className="main-lead">
              We are a reliable partner that you can count on no matter the
              stage of your project. Our team of experts can provide assistance
              during the planning stage, the execution phase, and up to the last
              steps for completion. We can also assist in the cleanup process or
              any restoration project.
            </p>
            <p className="main-lead">
              Pacific Coast always aims for the most positive outcome for our
              clients and their plans.
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <Link to="#our-team" className="scroll-btn">
            <FaCaretDown
              
              className="text-white caret-down"
            />
          </Link>
          <p className="hidden-text mt-2">our team</p>
        </div>
      </div>{" "}
    </>
  )
}

export default ChooseUs
