import React from "react"
import { Link } from "gatsby"

const WhatToExpect = () => {
  return (
    <>
      <div className="col-lg-10 mx-auto px-0">
        <h2>What Should You Expect?</h2>
        <p className="main-lead mt-5 mb-0">
          You will find that our list of services can help your projects from
          start to finish.
        </p>
        <p className="main-lead mt-0">
          Pacific Coast offers this comprehensive list of smart solutions:
        </p>
        <div className="mt-5">
          <ul className="d-flex w-100 justify-content-between list-wrapper">
            <div>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Right of Way
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Title searches
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Title Audit
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Curative Title
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Acquisitions
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Real Estate
                {/* </a> */}
              </li>
            </div>
            <div>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Relocation
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Digital Data Extraction
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Power Poles
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Power Towers
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Towers
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Distribution Poles
                {/* </a> */}
              </li>
            </div>
            <div>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Land Management Systems
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Project Management Database
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Linear projects
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Land Database Management
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                {/* <a href="/" className="expect-cta"> */}
                Public Works
                {/* </a> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="hexagon-list mr-2" />
                <Link to="/vegetation-management" className="expect-cta">
                  Vegetation Management
                </Link>
              </li>
            </div>
          </ul>
        </div>
        <p className="main-lead mb-5 mt-5">
          Pacific Coast Land Solutions works with midstream companies, Oil & Gas
          producers, pipeline companies, utility companies, and energy companies
          to streamline their projects and sort out any land-related issues.
        </p>
      </div>{" "}
    </>
  )
}

export default WhatToExpect
