import React, { useState } from "react"
import { Link } from "gatsby"

import { Modal } from "react-bootstrap"
// import pipelinesImg from "../../images/our-services/pipelines.png"
import OtherServices from "../our-services/other-services"
// import VegManagement from "../veg-management/veg-management"

import pipelinesImg from "../../images/our-services/pipelines.png"
import elecTransmissionImg from "../../images/our-services/electric-transmission.png"
import renewEnergyImg from "../../images/our-services/renewable-energy.png"
import publicWorksImg from "../../images/our-services/public-works.png"
// import geoAmpsImg from "../../images/our-services/geoAMPS.png"
import pandellImg from "../../images/our-services/pandell.png"
import irth from "../../images/irth.png"
import artificialIntImg from "../../images/our-services/artificial-intelligence.png"
import vegetation from "../../images/our-services/vegetation.png"

const Services = () => {
  const [lgShow, setLgShow] = useState(false)
  const [lgShow1, setLgShow1] = useState(false)
  const [lgShow2, setLgShow2] = useState(false)
  const [lgShow3, setLgShow3] = useState(false)
  // const [lgShow4, setLgShow4] = useState(false)
  // const [lgShow5, setLgShow5] = useState(false)
  return (
    <>
      <div className="services-wrapper lazy" id="services">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <h3 className="title text-center">services</h3>
              <p className="lead text-center mt-5">
                No other business offers the kind of consistency, efficiency or
                comprehensive support that Pacific Coast Land Solutions
                provides. Our people, processes & technologies are one of a
                kind.
              </p>
            </div>
            <div className="col-lg-10 mx-auto">
              <div className="d-flex flex-column align-items-center mt-5">
                <div className="d-flex services-wrapper-1">
                  <div className="services-box">
                    <button onClick={() => setLgShow(true)}>
                      <div className="hexa-wrapper hexa-pipeline d-flex justify-content-center align-items-center">
                        <img src={pipelinesImg} alt="pipelines" />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>pipelines</p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </button>

                    <Modal
                      className="modal-wrapper"
                      size="lg"
                      show={lgShow}
                      onHide={() => setLgShow(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Pipelines</h3>
                        <div className="services-description-box mt-3">
                          <p className="text-white">
                            Pacific Coast Land Solutions provide expert
                            assistance in the field of pipelines for clients in
                            the oil, gas, energy, public utilities, and power
                            industries. Clients can leverage our knowledge and
                            connections to reduce the risk of their projects. At
                            the same time, clients can enjoy efficiency and
                            optimal solutions for every aspect they need help
                            with. Our team will be ready to help from the
                            initial planning stage to project completion.
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                  <div className="services-box">
                    <button onClick={() => setLgShow1(true)}>
                      <div className="hexa-wrapper hexa-electransmission d-flex justify-content-center align-items-center">
                        <img
                          src={elecTransmissionImg}
                          alt="electric transmission"
                        />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>
                            Electric
                            <br />
                            Transmission
                          </p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </button>

                    <Modal
                      size="lg"
                      show={lgShow1}
                      onHide={() => setLgShow1(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                      className="modal-wrapper"
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Electric Transmission</h3>
                        <div className="services-description-box mt-3">
                          <p className="mt-3">
                            Pacific Coast is your reliable partner in the Right
                            of Way field for your large-scale Transmission
                            projects. We have a proven track record of
                            accomplishments. Benefit from our solid commitment
                            to providing flexible, smart, and top-quality
                            solutions to our clients. We also assist in ensuring
                            that environmental damage is minimized for each
                            project.
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                  <div className="services-box">
                    <button onClick={() => setLgShow2(true)}>
                      <div className="hexa-wrapper hexa-renew d-flex justify-content-center align-items-center">
                        <img src={renewEnergyImg} alt="Renewable Energy" />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>
                            Renewable
                            <br />
                            Energy
                          </p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </button>

                    <Modal
                      className="modal-wrapper"
                      size="lg"
                      show={lgShow2}
                      onHide={() => setLgShow2(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Renewable Energy</h3>
                        <div className="services-description-box mt-3">
                          <p>
                            Pacific Coast will provide Right of Way and land
                            solutions for all your large-scale solar and wind
                            projects. Our professional team will help you
                            achieve your goals in a socially responsible and
                            environmentally friendly manner. We make sure that
                            your project goes smoothly and safely.
                          </p>
                          <p>
                            We offer two products for wind energy: MET towers
                            and radar towers. MET stands for meteorlogical
                            tower. In larger scale wind farms we provide these
                            towers to the farms and our towers hold the the
                            special instruments like anemometers to precisely
                            measure wind. For the radar towers, which is a new
                            market, wind farms are having the radar towers
                            installed to replace the always-on lighting with a
                            radar tower that can identify when a plane is
                            approaching and turn on the lighting. Every wind
                            farm in the nation will be switching to this set up.
                          </p>
                          <a
                            href="https://www.nelloinc.com/wind-energy-nello-corporation.cfm"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="submit-btn mt-5"
                          >
                            Nello Inc Renewable Page
                          </a>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                  <div className="services-box">
                    <button onClick={() => setLgShow3(true)}>
                      <div className="hexa-wrapper hexa-pubworks d-flex justify-content-center align-items-center">
                        <img src={publicWorksImg} alt="Public Works" />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>Public Works</p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </button>

                    <Modal
                      className="modal-wrapper"
                      size="lg"
                      show={lgShow3}
                      onHide={() => setLgShow3(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Public Works</h3>
                        <div className="services-description-box mt-3">
                          <p>
                            If you are looking to partner with a company that
                            will help your public works projects to be completed
                            on time, to be on-budget, and to follow local
                            regulations to the letter - then Pacific Coast Land
                            Solutions are ready to be of service.
                          </p>
                          <p>
                            We work with State, Federal, and DOT clients to
                            smoothly solve their Right of Way concerns. Our team
                            is equipped with years of knowledge and expertise to
                            minimize the risk and solve the minor or major
                            hiccups of a project. We invite you to leverage our
                            top-notch managerial oversight and quality assurance
                            measures to find the most optimal land solutions for
                            your projects.
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
                <div className="d-flex services-wrapper-2">
                  <div className="services-box">
                    <a
                      href="https://www.irthsolutions.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div className="hexa-wrapper hexa-pandell d-flex justify-content-center align-items-center">
                        <img loading="lazy" src={pandellImg} alt="Irth" />
                        <div className="overlay" />
                        <div className="text-center text-wrapper no-caps">
                          <p>Irth Solutions</p>
                          <span className="mt-5">Learn More</span>
                        </div>
                      </div>
                    </a>

                    {/* <Modal
                      className="modal-wrapper"
                      size="lg"
                      show={lgShow4}
                      onHide={() => setLgShow4(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3 className="no-caps">geoAMPS</h3>
                        <div className="services-description-box mt-3">
                          <p>
                            We work hand in hand with geoAMPS to bring the most
                            sophisticated and competent land management software
                            to our clients and the energy world. As the only DBE
                            Certified Preferred Partner of geoAMPS, we provide
                            dedicated geoAMPS implementation teams to new and
                            existing geoAMPS clients as well as continued expert
                            support using and leveraging this incredible
                            technology.
                          </p>
                          <img
                            src={require("../../images/geoAMPS_certified.png")}
                            className="img-fluid my-4 d-block"
                            alt="GeoAMPS Certification"
                          />
                          <a
                            href="/files/Certified-Preferred-Partner_PacificCoast.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="white-transparent-btn d-inline-block my-3"
                          >
                            View Certification
                          </a>
                        </div>
                      </Modal.Body>
                    </Modal> */}
                  </div>
                  <div className="services-box">
                    {/* <button onClick={() => setLgShow5(true)}> */}
                    <Link to="/artificial-intelligence">
                      <div className="hexa-wrapper hexa-artint d-flex justify-content-center align-items-center">
                        <img
                          src={artificialIntImg}
                          alt="Artificial Intelligence"
                        />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>
                            Automated Digital Data Extraction Using Artificial
                            Intelligence
                          </p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </Link>
                    {/* </button> */}

                    {/* <Modal
                      size="lg"
                      show={lgShow5}
                      onHide={() => setLgShow5(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                      className="modal-wrapper"
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>
                          Automated Digital Data Extraction Using Artificial
                          Intelligence
                        </h3>
                        <div className="services-description-box mt-3">
                          <p>
                            The beauty of combining human knowledge and
                            expertise with artificial intelligence and machine
                            learning is that clients receive cohesive reports
                            and distributed datasets for their projects. We also
                            help transform physical records into digital data,
                            which will definitely help in risk management,
                            future analyses, and smart decision making.
                          </p>
                          <iframe
                            width="100%"
                            height="500px"
                            src="https://www.youtube.com/embed/4tP0IYREUbI"
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Pacific Coast ROW Analyzer"
                          />
                        </div>
                      </Modal.Body>
                    </Modal> */}
                  </div>
                  <div className="services-box">
                    <Link to="/vegetation-management">
                      <div className="hexa-wrapper hexa-artint d-flex justify-content-center align-items-center">
                        <img src={vegetation} alt="Vegetation Management" />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>Vegetation Management</p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </Link>

                    {/*  */}
                  </div>
                </div>
                <div className="d-flex services-wrapper-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <OtherServices />{" "}
    </>
  )
}

export default Services
