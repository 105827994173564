import React from "react"
// import { Link } from "gatsby"

const RequestQuote = () => {
  return (
    <>
      <div className="request-quote-wrapper">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-lg-1">
              <div
                className="horizontal-line"
                style={{ visibility: "hidden" }}
              />
            </div> */}
            <div className="col-lg-12 text-center">
              <h3 className="title">WE ARE PACIFIC COAST</h3>
              <p className="mt-4 mb-0 lead">
                Benefit from Pacific Coast’s competitive prices, agile and
                high-quality services, profitable partnerships, and
                state-of-the-art AI data extraction. Together, we can come up
                with strategies that will lead to the best results for your
                projects. Get in touch with us and let us start working towards your success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestQuote
