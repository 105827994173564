import React from "react"
import { Link } from "gatsby"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FaCaretUp } from "react-icons/fa";

import mountainsInMap from "../../images/contact-us/california.jpg"

import WebriQForm from "@webriq/gatsby-webriq-form"

const ContactSection = () => {
  return (
    <>
      <div className="contact-wrapper lazy" id="contact">
        <div
          className="container-fluid"
          // style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        >
          <div className="row">
            <div className="col-lg-2">
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="title vertical-text">contact</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <h3 className="main-title">contact us</h3>
              <p className="phrase">COAST-TO-COAST CONFIDENCE</p>
              <p className="lead mt-5 mb-5">
                Pacific Coast Land Solutions was formed with our clients in
                mind. We have taken years of knowledge and expertise within the
                Right of Way profession to form a comprehensive organization
                that you can count on for all your Right of Way needs.
              </p>
              <WebriQForm
                method="POST"
                data-form-id="b4b0af1c-8b02-4c29-87fa-dc0740ef3717"
                name="Newsletter Form"
                className="form-newsletter"
                data-thankyou-url="/thank-you"
              >
                <div className="form-row">
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="Your Name"
                        id="full_name"
                        placeholder="YOUR NAME"
                      />
                      <input
                        className="form-control"
                        type="email"
                        name="Email"
                        id="email_add"
                        placeholder="EMAIL"
                      />
                      <input
                        className="form-control"
                        type="text"
                        name="Subject"
                        id="subject"
                        placeholder="SUBJECT"
                      />
                      <textarea
                        className="form-control"
                        type="text"
                        name="Message"
                        id="your_message"
                        cols="3"
                        rows="3"
                        placeholder="YOUR MESSAGE"
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <button
                        className="btn hvr-shadow submit-btn"
                        type="submit"
                      >
                        SEND
                      </button>
                    </div>
                  </div>
                </div>
              </WebriQForm>
            </div>
          </div>
        </div>{" "}
        <div className="d-flex align-items-center justify-content-center flex-column">
          <Link to="#" className="scroll-btn-reverse">
            <FaCaretUp 
              
              className="text-white caret-down"
            />
          </Link>{" "}
          <p className="hidden-text mt-2"> to the topmost </p>{" "}
        </div>{" "}
      </div>{" "}
      <div className="colorado-mountains">
        <img
          src={mountainsInMap}
          alt="Colorado Mountains"
          className="img-fluid w-100 lazy"
        />{" "}
      </div>{" "}
    </>
  )
}

export default ContactSection
