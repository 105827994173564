import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../sass/main.scss"

import HeroSection from "../components/hero/hero"
import RequestQuoteSection from "../components/request-quote/request-quote"
import AboutSection from "../components/about/about-section"
import TeamSection from "../components/our-team/team-section"
import ServicesSection from "../components/our-services/services-section"
import ContactSection from "../components/contact-us/contact-section"

const Index = () => {
  return (
    <Layout>
      <SEO
        title="Right of Way & Titles"
        description="Pacific Coast Land Solutions provide right of way and title services for midstream companies, oil & gas producers, energy and utility companies, and more."
      />
      <HeroSection />
      <RequestQuoteSection />
      <AboutSection />
      <TeamSection />
      <ServicesSection />
      <ContactSection />
    </Layout>
  )
}

export default Index
