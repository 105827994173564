import React, { useState } from "react"

import { Link } from "gatsby"

import SwiperCore, { Pagination, Navigation, A11y, Autoplay } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.scss"

import "swiper/components/pagination/pagination.scss"
import "swiper/components/navigation/navigation.scss"

// import ovalScroll from "../../images/round-scroll.svg"
import { FaCaretDown } from "react-icons/fa";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// import PrefPartnerGeoAMPS from "../../files/Certified-Preferred-Partner_PacificCoast.pdf"

import { Modal } from "react-bootstrap"
import HawkEyeLogo from "../../images/hawkeye-logo.png"

SwiperCore.use([Pagination, Navigation, A11y, Autoplay])

const HeroSection = () => {
  const [lgShow, setLgShow] = useState(false)
  // const [lgShowCert, setLgShowCert] = useState(false)
  // const swiperRef = useRef(null)

  // const goNext = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slideNext()
  //   }
  // }
  // const goPrev = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slidePrev()
  //   }
  // }

  return (
    <>
      <div className="hero-wrapper" id="hero">
        <Swiper
          // spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 10000,
            disableOnInteraction: true,
          }}
          // ref={swiperRef}
          navigation
        >
          <SwiperSlide>
            <div className="slides first-slide">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-1">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-9">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">Welcome to</p>
                      <h1 className="hero-title-text">Pacific Coast</h1>
                      <h2 className="hero-subtitle-text">Land Solutions</h2>
                      <p className="hero-lead-text mt-5 mb-0">
                        Pacific Coast Land Solutions is your reliable partner in
                        Right of Way acquisitions and Title services. Expect
                        professionalism and reliability when you partner with
                        us. Our comprehensive services are designed to put
                        clients first - always.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 social-media-box">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/pacific-coast-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                      {/* <Link to="/" className="vertical-text">
                        twitter
                      </Link>
                      <Link to="/" className="vertical-text">
                        instragam
                      </Link>
                      <Link to="/" className="vertical-text">
                        facebook
                      </Link> */}
                    </div>
                  </div>
                  <div className="col-lg-12" />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides second-slide">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-1">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-9">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">MORE THAN</p>
                      <h1 className="hero-title-text">ARTIFICIAL</h1>
                      <h1 className="hero-title-text">INTELLIGENCE</h1>
                      <h2 className="hero-subtitle-text">
                        Automated digital
                        <br />
                        data extraction
                      </h2>
                      <div className="row">
                        <div className="col-lg-4 col-md-8 ml-lg-2 ml-md-2 align-self-center">
                          <img
                            src={HawkEyeLogo}
                            alt="HawkEye Logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-5 my-lg-5 my-md-5 my-5">
                          <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/oHzXzk96wHw"
                            title="Hawkeye Powered by Grooper"
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{
                              border: "2px solid #248dcb",
                              borderRadius: "5px",
                              height: "350px",
                            }}
                          />
                        </div>
                        <div className="col-lg-12">
                          <p className="hero-lead-text mb-0">
                            Using machine learning, artificial intelligence, and
                            our expertise we help companies turn physical
                            records into digital data, and distributed datasets
                            into cohesive reports.
                          </p>
                        </div>
                      </div>

                      <button
                        className="submit-btn mt-5 mr-lg-5 mr-md-5 mb-md-0 mb-3 mr-0 border-0"
                        onClick={() => setLgShow(true)}
                      >
                        HOW IT WORKS!
                      </button>
                      <Link
                        to="/artificial-intelligence"
                        className="submit-btn"
                      >
                        Learn More
                      </Link>

                      <Modal
                        size="lg"
                        show={lgShow}
                        onHide={() => setLgShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                        centered
                        className="modal-wrapper"
                      >
                        <Modal.Header
                          closeButton
                          style={{ borderBottom: "none" }}
                        />
                        <Modal.Body>
                          <div className="services-description-box mt-3">
                            <iframe
                              width="100%"
                              height="500px"
                              src="https://www.youtube.com/embed/4tP0IYREUbI"
                              frameBorder="0"
                              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Pacific Coast ROW Analyzer"
                            />
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                  <div className="col-lg-2 social-media-box">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/pacific-coast-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                      {/* <Link to="/" className="vertical-text">
                        twitter
                      </Link>
                      <Link to="/" className="vertical-text">
                        instragam
                      </Link>
                      <Link to="/" className="vertical-text">
                        facebook
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides third-slide">
              <div className="container-fluid">
                <div className="row" style={{ marginBottom: "6rem" }}>
                  <div className="col-lg-1 col-sm-12">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-9 col-sm-12">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">
                        authorized seller for
                      </p>
                      <h1 className="hero-title-text">NELLO inc</h1>
                      <h2 className="hero-subtitle-text">
                        Communication
                        <br />
                        Tower Manufacturer
                      </h2>
                      {/*<p className="hero-lead-text mt-5 mb-0">
                        Take advantage of our partnership with Nello for your
                        communication tower, tapered monopoles, and lattice
                        tower needs. Get access to high-quality tapered steel
                        poles for utility industry purposes.
                      </p> */}
                      <p className="hero-lead-text mt-5 mb-0">
                        NELLO is a global leader in the design and fabrication
                        of galvanized steel towers and poles. We service a wide
                        variety of markets specializing in tapered utility pole
                        and transmission pole structures as well as wireless
                        self-supporting towers, guyed towers, and monopoles.
                        Lead by a team of experienced engineering, design, and
                        manufacturing professionals, NELLO has grown to one of
                        the larger infrastructure providers in North America.
                        Today, we continue to add manufacturing and distribution
                        facilities to meet the needs of our growing customer
                        base. Our extensive design and manufacturing experience
                        allows us to develop new cost effective and innovative
                        products for our customers.
                      </p>
                      <a
                        href="https://www.nelloinc.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="submit-btn mt-5"
                      >
                        {/* For more infornation, visit: https://www.nelloinc.com/ */}
                        navigate to nello inc
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-2 social-media-box">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/pacific-coast-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                      {/* <Link to="/" className="vertical-text">
                        twitter
                      </Link>
                      <Link to="/" className="vertical-text">
                        instragam
                      </Link>
                      <Link to="/" className="vertical-text">
                        facebook
                      </Link> */}
                    </div>
                  </div>
                </div>
                {/* <div className="text-right mt-4">
                  <button className="mr-3" onClick={goPrev}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      className="bi bi-arrow-left-circle"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M8.354 11.354a.5.5 0 0 0 0-.708L5.707 8l2.647-2.646a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708 0z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M11.5 8a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5z"
                      />
                    </svg>
                    <div>PREV</div>
                  </button>
                  <button onClick={goNext}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      className="bi bi-arrowRight-circle"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M7.646 11.354a.5.5 0 0 1 0-.708L10.293 8 7.646 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M4.5 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"
                      />
                    </svg>
                    <div>NEXT</div>
                  </button>
                </div> */}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides geoAMPS-slide">
              <div className="container-fluid">
                <div className="row" style={{ marginBottom: "6rem" }}>
                  <div className="col-lg-3 col-sm-12">
                    <div className="horizontal-line" />
                  </div>
                  {/* <div className="col-lg-9 col-sm-12">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">
                        from our trusted partner
                      </p>
                      <h1 className="hero-title-text no-caps">
                        Pandell Projects
                      </h1>
                      <h2 className="hero-subtitle-text">
                        Improve Acquisition
                        <br />
                        Management
                      </h2>
                      <h3>Land Acquisition Software</h3>
                      <p className="hero-lead-text mt-5 mb-0">
                        Pandell Projects is land acquisition software that
                        facilitates workflow and communication for energy
                        companies and land agents.
                      </p>
                      <a
                        href="https://www.pandell.com/energy/pandellprojects/?videoTour=1"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="submit-btn  mt-5 mr-lg-5 mr-md-5 mb-md-0 mb-3 mr-0 no-caps"
                      >
                    
                        WATCH THE VIDEO
                      </a>
                      <a
                        href="https://www.pandell.com/energy/pandellprojects/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="submit-btn  mt-5 mr-lg-5 mr-md-5 mb-md-0 mb-3 mr-0 no-caps"
                      >
                        <span className="mt-5">
                          Learn about land acquisition software
                        </span>
                      </a>
                    </div>
                  </div> */}
                  <div className="col-lg-9 col-sm-12 social-media-box">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/pacific-coast-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides vegmngmnt-slide">
              <div className="container-fluid">
                <div className="row" style={{ marginBottom: "6rem" }}>
                  <div className="col-lg-1 col-sm-12">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-9 col-sm-12">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">provider of</p>
                      <h1 className="hero-title-text no-caps">
                        Vegetation Management
                      </h1>

                      <p className="hero-lead-text mt-5 mb-0">
                        Pacific Coast Land Solutions is a California Public
                        Utility Commission and six (6) State DOT “DBE” Certified
                        utility/energy/right-of-way consulting company in the
                        U.S. We enable our clients to manage their interests in
                        a responsible and self-determined way so they can
                        proactively manage vegetation across their entire
                        rights-of-way. We stress continuing education and
                        acquiring industry certifications to our team members to
                        stay on top of and to share relevant industry content
                        with our clients around the country.
                      </p>
                      <Link
                        to="/vegetation-management"
                        className="submit-btn mt-5 mr-lg-5 mr-md-5 mr-0"
                      >
                        {/* For more infornation, visit: https://www.nelloinc.com/ */}
                        LEARN MORE
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-2 social-media-box">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/pacific-coast-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <div className="d-flex align-items-center justify-content-center flex-column scroll-btn-wrapper">
            <Link to="#about-section" className="scroll-btn">
              <FaCaretDown 
               
                className="text-white caret-down"
              />
            </Link>
            <p className="hidden-text mt-2">about us</p>
          </div>
        </Swiper>
        {/* <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <Link to="#about-section" className="scroll-btn">
              <FontAwesomeIcon
                icon={faCaretDown}
                className="text-white caret-down"
              />
            </Link>
            <p className="hidden-text mt-2">about us</p>
          </div>
        </div> */}
      </div>{" "}
    </>
  )
}

export default HeroSection
