import React, { useState } from "react"

import { Modal } from "react-bootstrap"

import headShotFull1 from "../../images/our-team/cash-diel-full.jpg"
import headShotFull2 from "../../images/our-team/eli-lyons-full.jpg"
import headShotFull3 from "../../images/our-team/troy-schell-full.jpg"
import headShotFull4 from "../../images/our-team/DIanelloPic.jpg"
import headShotFull5 from "../../images/our-team/danny-p-gibbs-full.jpg"

import headShot1 from "../../images/our-team/cash-diel.png"
import headShot2 from "../../images/our-team/eli-lyonis.png"
import headShot3 from "../../images/our-team/troy-schell.png"
import headShot4 from "../../images/our-team/ianello.png"
import headShot5 from "../../images/our-team/danny-p-gibbs.png"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FaEnvelope } from "react-icons/fa";

const VerticallyCenteredModal1 = props => {
  const [modalShow1, setModalShow1] = useState(false)
  const [mid, setMid] = useState(1)
  const handleClose = () => {
    setModalShow1(false)
    setMid(1)
  }

  const handleShow = () => {
    setModalShow1(true)
    setMid(
      props.photoButton1
        ? 1
        : props.photoButton2
        ? 2
        : props.photoButton3
        ? 3
        : props.photoButton4
        ? 4
        : props.photoButton5
        ? 5
        : null
    )
  }

  const teams = [
    {
      id: 1,
      headshot: headShotFull1,
      name: "Cash I. Diel",
      position: "President",
      email: "cash@pacificcoastls.com",
      description:
        "<p>Pacific Coast Land Solutions co-founder and President, Cash Diel, has 16 years of experience in the Right of Way industry and in the Oil & Gas field. His considerable experience ensures that our clients receive nothing but the best service.</p><p>Mr. Diel is at the helm of Pacific Coast and makes sure that we run a tight ship. He oversees all active projects as well as the people involved in them. He also has a hand in the recruiting and hiring process, as well as other external operations. On top of that, he is responsible for developing and managing client relationships.</p><p>Aside from his extensive experience in the field, Mr. Diel also has a Bachelor’s Degree in Agricultural Sciences and Natural Resources, plus a Major in Agricultural Economics. He graduated from Oklahoma State University and also holds a Juris Doctorate from the University of Oklahoma College of Law.</p><p>Mr. Diel is also a licensed attorney in the State of Oklahoma and is a member of the IRWA and IRWA Pipeline Committee. The Right of Way Consultants Council has granted him the position of Treasurer as well.</p><p>Prior to Pacific Coast, our co-founder served as a Director of Field Services at a national energy consulting firm. There he started the Right of Way department and helped the firm achieve one success after another. He worked on projects involving Right of Way, environmental solutions, surveys, and more.</p><p>Mr. Diel is no stranger to planning and executing projects to the highest of standards. He has worked on small and big projects and continues to be heavily involved in projects involving oil & gas, pipelines, renewable energy, electric transmission, and midstream.</p><p>With him as an executive at Pacific Coast, our clients can rest assured that they are in the hands of a highly capable and knowledgeable professional.</p>",
    },
    {
      id: 2,
      headshot: headShotFull2,
      name: "Eli Lyons",
      position: "Principle - CEO",
      email: "eli@pacificcoastls.com",
      description:
        "<p>Eli Lyons brings 20 years of experience as an owner and as a senior executive of regional and national companies to his position. Prior to co-founding Pacific Coast Land Solutions, Mr. Lyons held the position of Vice President of Business Development at geoAMPS, LLC where he helped to introduce and implement the most state of art technology available to the Right of Way industry.  His efforts at geoAMPS helped them make the Forbes list two years in a row and helped thousands of users to leverage technology to become more efficient at their jobs. Mr. Lyons held the same position for a large national right of way company specializing in the pipeline and midstream sectors for 5 years prior to joining geoAMPS. His efforts helped the firm see incredible growth in that time period and grow from 15 right of way agents to over 300. Mr. Lyons also works with our Senior Executive Staff for our clients logistical and operational needs. Mr. Lyons is also actively engaged in the management of our existing projects.</p><p>Mr. Lyons received his Master of Science in Engineering & Technology Management from the Colorado School of Mines. He is fluent in English and Spanish, is an active member of the IRWA and holds a managing broker’s real estate license in the State of Colorado.</p><p>These days, you will find Mr. Lyons actively shaping our nationwide and internal operations. He works closely with our Senior Executive Staff and is mainly responsible for meeting our clients’ operational and logistical requirements. Along with our President, he is also involved with the management or Pacific Coast’s active projects.</p>",
    },
    {
      id: 3,
      headshot: headShotFull3,
      name: "Troy A. Schell",
      position: "VP of Legal",
      email: "troy@pacificcoastls.com",
      description:
        "<p>Pacific Coast’s VP of Legal is Troy A. Schell Troy has over 20 years of experience in the field of law. His expertise particularly shines when it comes to Mergers and Acquisitions & Real Estate transactions in the Private Sector. His work heavily involves the Healthcare, Construction, and Real Estate industries.</p><p>Before starting his own law office, Mr. Schell held the General Counsel position for one of the biggest healthcare firms in the country. While there, he was able to push through the acquisition of nearly two dozen hospitals. These include healthcare establishments in California, Georgia, Missouri, Texas, and Kansas.</p><p>Mr. Schell holds a Juris Doctorate Degree from Pepperdine University. He regularly gives talks about a wide variety of legal topics as well as business and regulatory-related legalese.</p>",
    },
    {
      id: 4,
      headshot: headShotFull4,
      name: "Dan Ianello",
      position: "President of Manufactured Towers & Poles Division",
      email: "dan@pacificcoastls.com",
      description:
        "<p>Mr. Ianello is an accomplished Senior Executive and Board Member with more than 20 years of success across the manufacturing industry. Leveraging extensive experience developing growth management strategies for companies in transition, he is a valuable asset for organizations trying to restructure business to grow into to different markets and develop a new customer base. </p><p>His broad areas of expertise include engineering, finance, strategic planning and implementation, marketing, and sales. Throughout his executive career, Mr. Ianello has held leadership positions at organizations including Nicor Gas, Valmont Industries, and currently CEO of Nello Corporation. </p><p> Mr. Ianello has seen tremendous success over the years and has served as a key contributor in the achievements of organizations he’s worked with. Mr. Ianello has been responsible for the negotiation process to move Nello operations from Texas to Indiana by working with Indiana state officials, developing incentives for the state, and building a new facility for the company. Mr. Ianello has also served as the manager for engineering and new product development for Valmont, overseeing both departments and developing leadership expertise. </p><p> Mr. Ianello is a licensed Professional Engineer and holds patents on several innovative products in the wireless industry. Mr. Ianello received his Bachelor of Science in Civil Engineering from Michigan State University and his Executive Master of Business Administration from the University of Chicago’s Booth School of Business.</p>",
    },
    {
      id: 5,
      headshot: headShotFull5,
      name: "Danny P. Gibbs",
      position: "Director of Business Development",
      // email: "dan@pacificcoastls.com",
      description:
        "<p>Danny Gibbs brings more than 30 years of energy industry knowledge and about 20 years of leadership experience to Gray Hawk Land Solutions.</p><p>At Spectra Energy, Danny served as Director of ROW Acquisition for the company’s capital expansion projects and also held the role of Director of Public Affairs - U.S. natural gas pipelines. Danny was integral in the development and implementation of noteworthy communications training and advocacy positions including Spectra’s Ambassador program and the Eight Commitments to Landowners (the “Landowner Bill of Rights”) adopted by Interstate Natural Gas Association of America companies in 2008. An award-winning communicator and Fortune 500 company spokesman, Danny is also an innovative team-building manager and passionate advocate of hydrocarbon energy and other infrastructure development projects. Danny has trained hundreds of energy company representatives to be better ambassadors for the industry.</p><p>Mr. Danny P. Gibbs earned a Bachelor of Arts in Journalism with a Public Relations emphasis from Texas Tech University. He is an active participant of the IRWA and is a member of the IRWA Pipeline Committee.</p>",
    },
  ]

  const filterdData = teams.filter(team => team.id === mid)

  // console.log(filterdData, "test")

  return (
    <>
      <div className="team-modal-box">
        <button onClick={handleShow}>
          {props.photoButton1 ? (
            <img
              className="img-fluid mb-3"
              src={headShot1}
              alt={filterdData[0].name}
            />
          ) : props.photoButton2 ? (
            <img
              className="img-fluid mb-3"
              src={headShot2}
              alt={filterdData[0].name}
            />
          ) : props.photoButton3 ? (
            <img
              className="img-fluid mb-3"
              src={headShot3}
              alt={filterdData[0].name}
            />
          ) : props.photoButton4 ? (
            <img
              className="img-fluid mb-3"
              src={headShot4}
              alt={filterdData[0].name}
            />
          ) : props.photoButton5 ? (
            <img
              className="img-fluid mb-3"
              src={headShot5}
              alt={filterdData[0].name}
            />
          ) : null}
        </button>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow1}
          onHide={handleClose}
          className="modal-wrapper"
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }} />
          <Modal.Body>
            <div className="m-wrap text-center mb-5">
              <img
                className="img-fluid"
                src={filterdData[0].headshot}
                alt={filterdData[0].name}
              />
            </div>
            <h2 className="text-center">{filterdData[0].name}</h2>
            <p className="text-center personnel-position">
              {filterdData[0].position}
            </p>
            {/* <p className="text-center">
              <a
                href={`mailto:${filterdData[0].email}`}
                className="personnel-email"
              >
                {filterdData[0].email}
              </a>
            </p> */}
            {filterdData[0].email ? (
              <div
                className="d-flex justify-content-center mx-auto"
                style={{ width: "60%" }}
              >
                <div className="d-flex align-items-center">
                  <FaEnvelope
                   
                    className="text-white caret-down"
                    style={{ fontSize: "1rem" }}
                  />
                  <a
                    href={`mailto:${filterdData[0].email}`}
                    className="text-white m-0 ml-2"
                  >
                    {filterdData[0].email}
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              className="team-description-box mt-5"
              dangerouslySetInnerHTML={{ __html: filterdData[0].description }}
            />
          </Modal.Body>
        </Modal>
      </div>{" "}
    </>
  )
}

export default VerticallyCenteredModal1
