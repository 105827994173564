import React from "react"
import { Link } from "gatsby"

// import ovalScroll from "../../images/round-scroll.svg"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FaCaretDown } from "react-icons/fa";

import VerticallyCenteredModal1 from "./team-modal"

const Team = () => {
  return (
    <>
      <div className="team-wrapper lazy" id="our-team">
        <div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <p className="sub-title">Meet the Pacific Coast</p>
                <h2 className="title">Executive Leadership Team</h2>
                <p className="mt-5 lead" style={{ width: "60%" }}>
                  Pacific Coast’s leadership team drives our processes and
                  people towards excellence. Get to know our executives and how
                  they can help make your plans a successful reality.
                </p>
              </div>
              <div className="col-lg-10 mx-auto">
                <div
                  className="row justify-content-center"
                  style={{ marginTop: "5rem", marginBottom: "5rem" }}
                >
                  <div className="col-md-6 col-lg-4 text-center mb-5 mb-md-0 px-md-3 px-lg-5">
                    <VerticallyCenteredModal1 photoButton2={true} />
                    <p className="personnel-name">Eli Lyons</p>
                    <p className="personnel-role">Principal - CEO</p>
                  </div>
                  <div className="col-md-6 col-lg-4 text-center mb-5 mb-md-0 px-md-3 px-lg-5">
                    <VerticallyCenteredModal1 photoButton1={true} />
                    <p className="personnel-name">Cash I. Diel</p>
                    <p className="personnel-role">President</p>
                  </div>
                  <div className="col-md-6 col-lg-4 text-center mb-5 mb-md-0 px-md-3 px-lg-5">
                    <VerticallyCenteredModal1 photoButton3={true} />
                    <p className="personnel-name">TROY SCHELL</p>
                    <p className="personnel-role">VP of Legal</p>
                  </div>
                  <div className="col-md-6 col-lg-4 text-center mb-5 mb-md-0 px-md-3 px-lg-5">
                    <VerticallyCenteredModal1 photoButton4={true} />
                    <p className="personnel-name">DAN IANELLO</p>
                    <p className="personnel-role">
                      President of Manufactured Towers & Poles Division
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-4 text-center mb-5 mb-md-0 px-md-3 px-lg-5">
                    <VerticallyCenteredModal1 photoButton5={true} />
                    <p className="personnel-name">Danny P. Gibbs</p>
                    <p className="personnel-role">
                      Director of Business Development
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 mx-auto mb-5">
                <h3>Let us help</h3>
                <p className="lead mt-5">
                  Partner with us for all your Right of Way, Real Estate, and
                  Land Title needs. Our highly experienced leadership team and
                  skilled staff will provide you with smart and efficient
                  solutions. Get in touch with us and let us come up with the
                  best strategies for your projects’ success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <Link to="#services" className="scroll-btn">
            <FaCaretDown 
              
              className="text-white caret-down"
            />
          </Link>
          <p className="hidden-text mt-2">services</p>
        </div>
      </div>{" "}
    </>
  )
}

export default Team
