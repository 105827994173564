import React, { useState } from "react"

import { Link } from "gatsby"

import adminImg from "../../images/our-services/other-services/admin-and-maintenance.png"
import gisImg from "../../images/our-services/other-services/gis-mapping.jpg"
import divisionOrdersImg from "../../images/our-services/other-services/division-orders.jpg"
import dataSolutionsImg from "../../images/our-services/other-services/data-solutions.jpg"
import accountingServicesImg from "../../images/our-services/other-services/accounting-services.jpg"
import mineralMngtImg from "../../images/our-services/other-services/mineral-management.jpg"
import digitizeDocsImg from "../../images/our-services/other-services/digitize-documents.jpg"
// import extraDataImg from "../../images/our-services/other-services/extract-data.jpg"
import reportsAndDashboard from "../../images/our-services/other-services/reports-and-dashboard.jpg"

import hawkeye from "../../images/hawkeye-logo.png"


import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  // Card,
  // Button,
  // CardTitle,
  // CardText,
  Row,
  Col,
} from "reactstrap"

import classnames from "classnames"

const OtherServices = () => {
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  return (
    <>
      <div className="other-services-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h4>
                ARTIFICIAL INTELLIGENCE
                <br />
                DIGITAL DATA EXTRACTION
              </h4>
            </div>
            <div className="col-lg-10 mx-auto">
              <div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12 mx-lg-0 align-self-center">
                    <img src={hawkeye} alt="HawkEye" className="img-fluid" />
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 pl-lg-5 pl-md-auto mx-auto mt-lg-0 mt-md-5 mt-5">
                    <div className="img-fluid">
                      <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/oHzXzk96wHw"
                        title="Hawkeye Powered by Grooper"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                          border: "2px solid #248dcb",
                          borderRadius: "5px",
                          height: "350px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 tab-nav mt-5">
                    <Nav>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            toggle("1")
                          }}
                        >
                          <div className="d-flex">
                            <span className="mr-5">01</span>
                            <p className="mb-0">Admin. & Maintenance</p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            toggle("2")
                          }}
                        >
                          <div className="d-flex">
                            <span className="mr-5">02</span>
                            <p className="mb-0">GIS Mapping</p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => {
                            toggle("3")
                          }}
                        >
                          <div className="d-flex">
                            <span className="mr-5">03</span>
                            <p className="mb-0">Division Orders</p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => {
                            toggle("4")
                          }}
                        >
                          <div className="d-flex">
                            <span className="mr-5">04</span>
                            <p className="mb-0">Data Solutions</p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "5" })}
                          onClick={() => {
                            toggle("5")
                          }}
                        >
                          <div className="d-flex">
                            <span className="mr-5">05</span>
                            <p className="mb-0">Accounting Services</p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "6" })}
                          onClick={() => {
                            toggle("6")
                          }}
                        >
                          <div className="d-flex">
                            <span className="mr-5">06</span>
                            <p className="mb-0">Mineral Management</p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "7" })}
                          onClick={() => {
                            toggle("7")
                          }}
                        >
                          <div className="d-flex">
                            <span className="mr-5">07</span>
                            <p className="mb-0">Digitize Documents</p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "8" })}
                          onClick={() => {
                            toggle("8")
                          }}
                        >
                          <div className="d-flex">
                            <span className="mr-5">08</span>
                            <p className="mb-0">Reports & Dashboards</p>
                          </div>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="col-lg-8 w-100 tab-contents mt-5">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={adminImg}
                                  alt="admin and maintenance"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div
                                className="content-enclosure"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="sbox1">
                                  <span>01</span>
                                  <Link
                                    to="/admin-and-maintenance"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p className="mb-0">Admin & Maintenance</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>Lease & Land Admin/Maintenance</p>
                                  <p>Project or Ongoing Support</p>
                                  <p>Data entry & Cleanup</p>
                                  <p>Complete Land Records Admin</p>
                                  {/* <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={gisImg}
                                  alt="GIS Mapping"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div className="d-flex content-enclosure align-items-center">
                                <div className="sbox1 d-flex flex-column justify-content-between">
                                  <span>02</span>
                                  <Link
                                    to="/gis-mapping"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p className="mb-0">GIS Mapping</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>Lease Maps</p>
                                  <p>Tract Maps</p>
                                  <p>Well Maps</p>
                                  <p>Pipeline Maps</p>
                                  {/* <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={divisionOrdersImg}
                                  alt="Division Orders"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div className="d-flex content-enclosure align-items-center">
                                <div className="sbox1 d-flex flex-column justify-content-between">
                                  <span>03</span>
                                  <Link
                                    to="/division-orders"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p className="mb-0">Division Orders</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>Perform Calculations</p>
                                  <p>Interest Verification</p>
                                  <p>Owner Relations</p>
                                  <p>Mailouts</p>
                                  {/* <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={dataSolutionsImg}
                                  alt="Data Solutions"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div className="d-flex content-enclosure align-items-center">
                                <div className="sbox1 d-flex flex-column justify-content-between">
                                  <span>04</span>
                                  <Link
                                    to="/data-solutions"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p className="mb-0">Data Solutions</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>Custom Reports</p>
                                  <p>Dashboards</p>
                                  <p>Data Conversions</p>
                                  <p>Spreadsheets</p>
                                  {/* <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={accountingServicesImg}
                                  alt="Accounting Services"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div className="d-flex content-enclosure align-items-center">
                                <div className="sbox1 d-flex flex-column justify-content-between">
                                  <span>05</span>
                                  <Link
                                    to="/accounting-services"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p className="mb-0">Accounting Services</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>Cut Administrative & Labor Costs!</p>
                                  <p>Revenue Accounting</p>
                                  <p>Joint Interest Billing</p>
                                  <p>Production Accounting</p>
                                  {/* <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={mineralMngtImg}
                                  alt="Mineral Management"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div className="d-flex content-enclosure align-items-center">
                                <div className="sbox1 d-flex flex-column justify-content-between">
                                  <span>06</span>
                                  <Link
                                    to="/mineral-management"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p className="mb-0">Mineral Management</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>Mineral Setup & Maintenance</p>
                                  <p>Dashboard Reporting</p>
                                  <p>Wells/DOI's/Payments/NRI's</p>
                                  <p>Operator Updates</p>
                                  {/* <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="7">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={digitizeDocsImg}
                                  alt="Digitize Documents"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div className="d-flex content-enclosure align-items-center">
                                <div className="sbox1 d-flex flex-column justify-content-between">
                                  <span>07</span>
                                  <Link
                                    to="/digitize-documents"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p className="mb-0">Digitize Documents</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>
                                    If you have machine typed, handwritten, or
                                    mixed-content documents and want them
                                    scanned with advanced OCR techniques for the
                                    best quality output possible, we can do
                                    that!
                                  </p>
                                  {/* <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      {/* <TabPane tabId="8">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={extraDataImg}
                                  alt="Extract Data"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div className="d-flex content-enclosure align-items-center">
                                <div className="sbox1 d-flex flex-column justify-content-between">
                                  <span>08</span>
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0">EXTRACT DATA</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>
                                    Data entry is a relic of the past! Stop
                                    having your employees type information that
                                    has already been written down, typed out, or
                                    entered in.
                                  </p>
                                  <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane> */}
                      <TabPane tabId="8">
                        <Row>
                          <Col sm="12">
                            <div className="services-box d-flex flex-column">
                              <div
                                className="img-placement align-self-end"
                                style={{ width: "95%" }}
                              >
                                <img
                                  src={reportsAndDashboard}
                                  alt="Reports and Dashboard"
                                  className="img-fluid w-100"
                                />
                              </div>
                              <div className="d-flex content-enclosure align-items-center">
                                <div className="sbox1 d-flex flex-column justify-content-between">
                                  <span>08</span>
                                  <Link
                                    to="/reports-and-dashboards"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p className="mb-0">Reports & Dashboards</p>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chevron-right"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="sbox2 w-100">
                                  <p>
                                    Having access to information is great, but
                                    until it is organized it won’t do you much
                                    good. You need reportsthat answer specific
                                    questions and a dashboard thatboth gives you
                                    a high-level overview and lets you
                                    drill-down into the details.
                                  </p>
                                  {/* <div className="read-btn-wrapper">
                                    <Link to="/">
                                      <span className="mr-2">read more</span>
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="caret-down"
                                      />
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OtherServices
